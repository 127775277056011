import type { FC } from "react";
import React from "react";
import { useIntl } from "react-intl";
// import { SocialBtns } from "./SocialBtns";

interface Props {}

export const LoginHeader: FC<Props> = React.memo(() => {
  const intl = useIntl();

  return (
    <>
      <div className="mb-5">
        <div
          className="text-center text-uppercase fw-bold fst-italic display-4 mb-20"
          style={{ fontFamily: "Open Sans" }}
        >
          cashback
        </div>
      </div>
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "AUTH.GENERAL.SIGNUP_TITLE" })}
        </h1>
        {/* end::Title */}
      </div>

      {/* <SocialBtns /> */}

      {/* <div className="separator separator-content my-14">
        <span className="w-125px text-gray-500 fw-semibold fs-7">
          {intl.formatMessage({ id: "GLOBAL.OR" })}
        </span>
      </div> */}
    </>
  );
});
