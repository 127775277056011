/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { useIntl } from "react-intl";
import { LoginHeader } from "./LoginHeader";
import { removeAllSpaces } from "../../../../_metronic/helpers";

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState<any>();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { saveAuth, setCurrentUser } = useAuth();
  const intl = useIntl();

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })
    ),
    // email: Yup.string()
    //   .email(intl.formatMessage({ id: "AUTH.INPUT.EMAIL" }))
    //   .min(
    //     3,
    //     intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
    //   )
    //   .max(
    //     50,
    //     intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
    //   )
    //   .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const initialValues = {
    username: ``,
    password: ``,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("username", values.username);
        params.append("password", values.password);

        const auth = await login(params);
        saveAuth(auth);
        const user = await getUserByToken();
        setCurrentUser(user);
        // setRefreshReCaptcha((r) => !r);
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        const statusText =
          (error?.response?.data?.detail?.[0]?.msg ||
            error?.response?.data?.detail) ??
          intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_CREDITINALS" });
        setStatus(statusText);
        setSubmitting(false);
        setLoading(false);
        // setRefreshReCaptcha((r) => !r);
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.username) {
      formik.values.username = removeAllSpaces(formik?.values?.username);
    }
  }, [formik?.values?.username]);

  useEffect(() => {
    if (formik?.values?.password) {
      formik.values.password = removeAllSpaces(formik?.values?.password);
    }
  }, [formik?.values?.password]);

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <LoginHeader />
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      <div className="fv-row mb-8">
        {/* <label className="form-label fs-6 fw-bolder text-dark mb-1 ms-1">
          {intl.formatMessage({ id: "AUTH.INPUT.USERNAME.TITLE" })}
        </label> */}
        <input
          placeholder={intl.formatMessage({
            id: "AUTH.INPUT.USERNAME.TITLE",
          })}
          {...formik.getFieldProps("username")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.username && formik.errors.username,
            },
            {
              "is-valid": formik.touched.username && !formik.errors.username,
            }
          )}
          type="text"
          name="username"
          autoComplete="off"
        />
        {formik.touched.username && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className="fv-row mb-3">
        {/* <label className="form-label fw-bolder text-dark fs-6 mb-1 ms-1">
          {intl.formatMessage({ id: "AUTH.INPUT.PASSWORD.TITLE" })}
        </label> */}
        <input
          placeholder={intl.formatMessage({
            id: "AUTH.INPUT.PASSWORD.TITLE",
          })}
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Wrapper */}
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        {/* begin::Link */}
        <Link to="/auth/forgot-password" className="link-primary">
          {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })} ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}
      {/* begin::Action */}

      <div className="d-grid mb-6 mb-lg-8">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: "AUTH.GENERAL.SIGNUP_BUTTON" })}
            </span>
          )}
          {/* {!token && (
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          )} */}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "GLOBAL.WAIT",
              })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        {/* <Link to="/">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mt-5"
            >
              {intl.formatMessage({
                id: "AUTH.GENERAL.BACK_BUTTON",
              })}
            </button>
          </Link> */}
      </div>
      {/* end::Action */}
      {/* <div className="text-gray-500 text-center fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.GENERAL.NO_ACCOUNT" })}{" "}
          <Link reloadDocument to="/auth/registration" className="link-primary">
            {intl.formatMessage({ id: "AUTH.GENERAL.REGISTER_BUTTON" })}
          </Link>
        </div> */}
    </form>
  );
}
